import { addClassNames } from "../../utils";
import Well from "./Well";

export default function Centered({ children }: { children: React.ReactNode }) {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      {addClassNames(children, Well, "w-full lg:w-2/3 m-2")}
    </div>
  );
}
