import { addClassNames, classNames } from "../../utils";

function Well({
  children,
  className,
  innerClassName,
}: {
  children: React.ReactNode;
  className?: string;
  innerClassName?: string;
}) {
  return (
    <div
      className={classNames("bg-gray-100 dark:bg-black/30 sm:rounded-lg text-gray-600 dark:text-gray-300", className)}
    >
      <div className={classNames("px-4 py-5 sm:p-6 space-y-2", innerClassName)}>{children}</div>
    </div>
  );
}

Well.Title = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <h3 className={classNames("text-base font-semibold leading-6 text-gray-900 dark:text-white mb-4", className)}>
    {children}
  </h3>
);

Well.DualHeader = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={classNames("flex items-center mb-6", className)}>
    {addClassNames(children, Well.Title, "grow !mb-0")}
  </div>
);

Well.Small = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <span className={classNames("block text-xs opacity-60 font-normal", className)}>{children}</span>
);

Well.Footer = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={classNames("pt-4 sm:pt-6", className)}>{children}</div>
);

function Rows({ children, className }: { children: React.ReactNode; className?: string }) {
  return <div className={classNames("divide-y divide-gray-200 dark:divide-gray-800", className)}>{children}</div>;
}
Well.Rows = Rows;

function Row({ children, className }: { children: React.ReactNode; className?: string }) {
  return <div className={classNames("flex items-center justify-between py-4 last:pb-0", className)}>{children}</div>;
}
Rows.Row = Row;

Row.Left = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={classNames("flex-grow text-sm font-semibold", className)}>{children}</div>
);

Row.Right = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={classNames("flex items-center", className)}>{children}</div>
);

export default Well;
